import * as studentApi from '../../api/Student';
import cookie from 'react-cookies';
import * as apiService from '../../api';

export const getLeadId = () => {
  return cookie.load('shawacademy_leadId');
};

export const getStudentInfo = async () => {
  const studentId = getLeadId();
  let studentInfo;
  await new apiService.default.Addons(studentId)
      .listLead()
      .then((response) => {
        studentInfo = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  return studentInfo;
};

export const getStudentInfoIncluded = async (leadId='') => {
  const studentId = leadId || getLeadId();
  let studentInfo;
  let subscriptionInfo;
  let registrationInfo;
  await new apiService.default.Addons(studentId)
      .listLeadAndRegistration()
      .then((response) => {
        studentInfo = response.data.data;
        const info = response.data.included;
        subscriptionInfo = info.filter((item) => {
          return item.type === 'subscriptions';
        })[0];
        registrationInfo = response.data.included.filter((item) => {
          return item.type === 'registrations';
        });
      })
      .catch((error) => {
        console.log(error);
      });
  return {studentInfo, subscriptionInfo, registrationInfo};
};


export const getStudentRegion = async () => {
  let geoData;
  await studentApi.checkStudentRegion()
      .then((response) => {
        geoData = response;
      })
      .catch((error) => {
        console.log(error);
      });
  return geoData;
};

export const getCoursesWithAddons = (addonInfo, studentInfo) => {
  const registeredCourseInfo =
  mergeData(studentInfo?.data, studentInfo?.included);
  const mergedAddonInfo = getMergedAddonInfo(addonInfo);
  return getCoursesToRender(registeredCourseInfo, mergedAddonInfo);
};


/**
 *
 *
 * @param {*} courseData
 * @param {*} addonData
 * @return {*} modifiedCoureData
 */
function getCoursesToRender(courseData, addonData) {
  const leadId = cookie.load('shawacademy_leadId');
  if (courseData?.length) {
    for (const course of courseData) {
      for (const module of course.relationships.modules.data) {
        if (addonData?.length) {
          for (const addon of addonData) {
            if ((addon.indexOf(module.id) > -1 ||
            addon.indexOf('certificate-') > -1) &&
              (addon.indexOf('cert') > -1 ||
             cookie.load(`cert_${module.id}_${leadId}`))) {
              if (addon === `${module.id}-module-cert` ||
              addon.indexOf('cert') >= 0 ||
              cookie.load(`cert_${module.id}_${leadId}`)) {
                module['ecertPurchased'] = true;
              }
            }
            if ((addon.indexOf(module.id) > -1 ||
            addon.indexOf('toolkit-') > -1 ||
            addon.indexOf(module.attributes.moduleslug) > -1) &&
              (addon.indexOf('toolkit') > -1 ||
            cookie.load(`toolkit_${module.id}_${leadId}`))) {
              if (addon === `${module.id}-module-toolkit` ||
              addon.indexOf('toolkit') >= 0 ||
              cookie.load(`toolkit_${module.id}_${leadId}`)) {
                module['toolkitPurchased'] = true;
              }
            }
            if (addon === '-toolkit') {
              module['toolkitPurchased'] = true;
            }
            if (addon === '-certificate') {
              module['ecertPurchased'] = true;
            }
            if (addon.indexOf('hard-copy-diploma') > -1 ||
            cookie.load(`hcd_${module.id}_${leadId}`)) {
              if (addon === `${module.id}-module-hard-copy-diploma` ||
              addon === `${module.id}-course-hard-copy-diploma` ||
              cookie.load(`hcd_${module.id}_${leadId}`)) {
                course.attributes['hcdPurchased'] = true;
              }
            }
          }
        }
      }
    }
    return courseData;
  }
}

/**
 *
 *
 * @param {*} addonData
 * @return {*} addonArray
 */
function getMergedAddonInfo(addonData) {
  const attributes = addonData?.getAddonInfo?.attributes;
  const finalAddonArray = [];
  if (attributes) {
    if (attributes.chargebee) {
      for (const item of attributes.chargebee.line_items) {
        finalAddonArray.push(item.entity_id);
      }
    }
    if (attributes.razorpay) {
      for (const item of attributes.razorpay.orders) {
        finalAddonArray.push(item.notes.addon_id);
      }
    }
    if (attributes.itunes) {
      for (const item of attributes.itunes.receipts) {
        finalAddonArray.push(item.product_id);
      }
    }
    if (attributes.default) {
      for (const item of attributes.default.items) {
        finalAddonArray.push(`${item.course_slug}-${item.product}`);
      }
    }
  }
  return finalAddonArray;
};


/**
 *
 *
 * @param {*} courseRegistrations
 * @param {*} includedData
 * @return {*} modifiedResponse
 */
function mergeData(courseRegistrations, includedData) {
  if (courseRegistrations && courseRegistrations.length) {
    if (includedData && includedData.length) {
      for (const courseRegistration of courseRegistrations) {
        const moduleRegistrationsData =
        courseRegistration?.relationships?.modules?.data;
        if (moduleRegistrationsData.length > 1) {
          courseRegistration.attributes['hcdAvailable'] = true;
        } else {
          courseRegistration.attributes['hcdAvailable'] = false;
        }
        courseRegistration.attributes['hcdPurchased'] = false;
        for (const registration of moduleRegistrationsData) {
          registration['toolkitPurchased'] = false;
          registration['ecertPurchased'] = false;
          const filteredRegistration = includedData.filter((item) => {
            return item.id === registration.id;
          });
          registration['attributes'] = filteredRegistration[0].attributes;
        }
        const upcomingLessons = [];
        const today = new Date();
        for (const [i, module] of moduleRegistrationsData.entries()) {
          for (const lesson of module.attributes.lessons) {
            if (lesson.start_time) {
              courseRegistration['activeModule'] = i + 1;
            }
            if (lesson.start_time &&
              new Date(lesson.start_time) >= today) {
              upcomingLessons.push(lesson);
            }
            courseRegistration['nextLesson'] = upcomingLessons[0];
          }
        }
      }
      return courseRegistrations;
    }
  }
  return [];
};
