/**
   *
   * build lesson array
   * @param {*} schedule1
   * @param {*} schedule2
   * @param {*} length
   * @return {*}
   *
   */
import dayjs from 'dayjs';

export const getLessonArray = (schedule1, schedule2, length) =>{
  const lessons=[];
  const lessonLength = Math.ceil(length/2);

  for (let i = 0; i < lessonLength; i++) {
    let day1 = schedule1?.clone();
    day1 = day1.add(i*7, 'days').toISOString();
    let day2 = schedule2?.clone();
    day2 = day2.add(i*7, 'days').toISOString();

    lessons.push({
      number: lessons.length + 1,
      start_time: day1,
      status: 'watching',
    });
    lessons.push({
      number: lessons.length + 1,
      start_time: day2,
      status: 'watching',
    });
  }
  return lessons;
};

/**
   *
   * return week number
   * @param {*} schedule1
   * @param {*} schedule2
   * @return {*}
   *
   */
export const getDaysOfWeek = (schedule1, schedule2) => {
  let firstDayOfWeek;
  let secondDayOfWeek;

  if (schedule1?.day() === 0) {
    firstDayOfWeek = 7;
  } else {
    firstDayOfWeek = schedule1.day();
  }
  if (schedule2?.day() === 0) {
    secondDayOfWeek = 7;
  } else {
    secondDayOfWeek = schedule2.day();
  }
  return {
    'firstDayOfWeek': firstDayOfWeek,
    'secondDayOfWeek': secondDayOfWeek,
  };
};

/**
   *
   * return schedule request object
   * @param {*} rquestObject
   * @return {*}
   *
   */
export const createScheduleReqObject = ({
  courseStartDate,
  courseSlug,
  firstDayOfWeek,
  secondDayOfWeek,
  firstDayHour,
  secondDayHour,
  lessons,
  leadId,
}) => {
  return {
    'data': {
      'type': 'registrations',
      'attributes': {
        'course_start_date': courseStartDate,
        'course_slug': courseSlug,
        'schedule': {
          'first_day_of_week': firstDayOfWeek,
          'second_day_of_week': secondDayOfWeek,
          'first_day_hour': firstDayHour,
          'second_day_hour': secondDayHour,
          'lessons': lessons,
        },
      },
      'relationships': {
        'student': {
          'data': {
            'type': 'student',
            'id': leadId,
          },
        },
      },
    },
  };
};

export const getMergedResponse = (data) => {
  let chargebeeData = [];
  let iTunesData = [];
  let defaultData = [];
  if (data?.attributes?.chargebee) {
    chargebeeData = data?.attributes?.chargebee?.line_items;
  }
  if (data?.attributes?.itunes) {
    iTunesData = data?.attributes?.itunes?.receipts;
  }
  if (data?.attributes?.default) {
    defaultData = data?.attributes?.default?.items;
  }
  const responseFromApi = [...chargebeeData, ...iTunesData, ...defaultData];
  return getRestructureData(responseFromApi);
};

/**
 *
 *
 * @param {*} data
 * @return {*}
 */
function getRestructureData(data) {
  const commonAddons = [];
  if (data?.length) {
    for (let i = 0; i < data?.length; i++) {
      if (data[i].entity_type && data[i].entity_type === 'addon' &&
      (data[i].entity_id && data[i].entity_id.indexOf('lifetime') === -1 &&
      data[i].entity_id.indexOf('pre-auth-charges') === -1)) {
        commonAddons.push(data[i].entity_id);
      } else if ((data[i].notes && data[i].notes.course_id &&
        data[i].notes.coupon_id === '')) {
        commonAddons.push(data[i].notes.addon_id);
      } else if (data[i].product_id) {
        commonAddons.push(data[i].product_id);
      } else if (data[i].product === 'course-hard-copy-diploma') {
        const addonToPush = `${data[i].product}-${data[i].eqf_slug}`;
        commonAddons.push(addonToPush);
      } else if (data[i].product) {
        const addonToPush = `${data[i].product}-${data[i].course_slug}`;
        commonAddons.push(addonToPush);
      }
    }
  }
  return commonAddons;
}

export const checkGiftMembership = (studentMembership) => {
  if (studentMembership?.slug === 'gift-premium') {
    const eligibleDate = dayjs('2022-12-01');
    const membershipEndDate = dayjs(studentMembership.end_date);
    if (membershipEndDate < eligibleDate) {
      return true;
    }
  }
  return false;
};


