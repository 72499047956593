import cookie from 'react-cookies';
import * as constants from '../../containers/Constants';
import {Skylab} from '@amplitude/skylab-js-client';

export const checkPurchaseConsentEligibility = (countryParam) => {
  return true;
};

export const referralEligible = () => {
  let country = cookie.load('country') || 'us';
  if (euCountries().indexOf(country) > -1) {
    country = 'eu';
  }
  if (country === 'us' || country === 'eu' ||
  country === 'gb' || country === 'za' || country === 'au') {
    return true;
  }
  return false;
};

export const studentCurrency = () => {
  let country = cookie.load('country') || 'us';
  if (euCountries().indexOf(country) > -1) {
    country = 'eu';
  }
  const currencyInfo = constants.lowerPlans[0][country] || {};
  return currencyInfo;
};

export const euCountriesList = () => {
  return ['at', 'be', 'bg', 'hr',
    'cr', 'cz', 'dk', 'ee', 'fi', 'fr', 'de', 'hu', 'ie',
    'it', 'lv', 'lt', 'lu', 'mt', 'aw', 'pl', 'pt', 'ro',
    'sk', 'is', 'es', 'se'];
};

/**
 *
 *
 * @return {*}
 */
function euCountries() {
  return ['at', 'be', 'bg', 'hr',
    'cr', 'cz', 'dk', 'ee', 'fi', 'fr', 'de', 'hu', 'ie',
    'it', 'lv', 'lt', 'lu', 'mt', 'aw', 'pl', 'pt', 'ro',
    'sk', 'is', 'es', 'se'];
}

export const handleImageError = (e) => {
  e.target.src = '/images/transparent.svg';
};

export const checkEligibilityForNewDashboard = (country) => {
  let leadCountry=country;
  if (!country) {
    leadCountry=cookie.load('country');
  }
  if (euCountries().indexOf(leadCountry) > -1) {
    leadCountry = 'eu';
  }
  // 'us', 'gb', 'za', 'eu', 'nz' 'us'
  const oldDashboardCountries=[];
  return !oldDashboardCountries.includes(leadCountry);
};

export const checkMailinatorUser = (email) => {
  return email.endsWith('@mailinator.com');
};

export const getVariantValue = (flagName) => {
  const client = Skylab.instance();
  return client.getVariant(flagName);
};
